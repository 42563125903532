import { Header } from "antd/es/layout/layout";
import HeaderLogo from "../../assets/logo/sms-header-logo.svg";
import dotMenu from "../../assets/images/dotsmenu.svg";
import Callcenterimg from "../../assets/images/Call-Center.svg";
import contactsImg from "../../assets/images/Contacts.svg";
import liveCallImg from "../../assets/images/Live-calls.svg";
import PBXImg from "../../assets/images/PBX-Portal.svg";
import ReportsImg from "../../assets/images/Reports.svg";
import SMSImg from "../../assets/images/SMS.svg";
import { Avatar, Flex, Popover, Typography } from "antd";
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import { generateNameSpaceColor } from "../../utils/color";

const { Text } = Typography;

const LayoutHeader = ({ name, logout }: { name: string, logout:()=>void }) => {
  const color = generateNameSpaceColor(name);
  const avatarStyle: React.CSSProperties = {
    verticalAlign: "middle",
    color: "black",
    fontWeight: "bold",
    cursor: "pointer",
    textTransform: "capitalize",
  };

  const dotMenuStyle: React.CSSProperties = {
    border: "1px solid white",
    borderRadius: "25px",
    cursor: "pointer",
    width: "30px",
    height: "30px",
    margin: "auto",
    padding: "5px",
  };

  const TextStyle: React.CSSProperties = {
    color: "rgb(119, 117, 135)",
  };
  const HeaderStyle: React.CSSProperties = {
    background: "#130d38",
    padding: "15px",
  };
  const HeaderContentStyle: React.CSSProperties = { height: "100%" };
  const HeaderMenu: React.CSSProperties = { gap: "20px" };
  const HeaderNameStyle: React.CSSProperties = {
    color: "white",
    margin: "auto",
    textTransform: "capitalize",
  };

  const headerLogo: React.CSSProperties = {
    cursor: "pointer",
  };

  const items: MenuProps["items"] = [
    {
      label: "Logout",
      key: "0",
      onClick: () => {
        logout()
      },
    },
  ];

  const dotMenuContent = (
    <Flex vertical={true}>
      <Flex>
        <Flex vertical={true} align="center" className="dot-menu-icons">
          <img src={Callcenterimg} alt="Linkedin" />
          <Text style={TextStyle}>Live Calls</Text>
        </Flex>
        <Flex vertical={true} align="center" className="dot-menu-icons">
          <img src={contactsImg} alt="apple icon" />
          <Text style={TextStyle}>Call center</Text>
        </Flex>
        <Flex vertical={true} align="center" className="dot-menu-icons">
          <img src={liveCallImg} alt="behance icon" />
          <Text style={TextStyle}>SMS</Text>
        </Flex>
      </Flex>
      <Flex>
        <Flex vertical={true} align="center" className="dot-menu-icons">
          <img src={PBXImg} alt="slack icon" />
          <Text style={TextStyle}>Contacts</Text>
        </Flex>{" "}
        <Flex vertical={true} align="center" className="dot-menu-icons">
          <img src={ReportsImg} alt="slack new icon" />
          <Text style={TextStyle}>PBX Portal</Text>
        </Flex>{" "}
        <Flex vertical={true} align="center" className="dot-menu-icons">
          <img src={SMSImg} alt="vk icon" />
          <Text style={TextStyle}>Reports</Text>
        </Flex>
      </Flex>
    </Flex>
  );

  return (
    <Header style={HeaderStyle}>
      <Flex align="center" justify="space-between" style={HeaderContentStyle}>
        <img style={headerLogo} src={HeaderLogo} alt="header logo" />

        <Flex style={HeaderMenu}>
          <Popover placement="bottom" content={dotMenuContent}>
            <img style={dotMenuStyle} src={dotMenu} alt="dot menu" />
          </Popover>

          <Text style={HeaderNameStyle}>{name}</Text>
          <Dropdown menu={{ items }} trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <Avatar
                style={{ ...avatarStyle, background: color }}
                size="large"
              >
                {name[0]}
              </Avatar>
            </a>
          </Dropdown>
        </Flex>
      </Flex>
    </Header>
  );
};

export default LayoutHeader;
