import axios from '../../node_modules/axios';
import Cookies from 'js-cookie';
function getToken() {
    return Cookies.get('sid');
}
export var createClient = function (baseURL) {
    var client = axios.create({
        baseURL: baseURL,
    });
    client.interceptors.request.use(function (config) {
        var token = getToken();
        if (token) {
            config.headers.Authorization = "Bearer ".concat(token);
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
    client.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        var _a;
        if (axios.isAxiosError(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 401) {
            console.error('Unauthorized access');
        }
        return Promise.reject(error);
    });
    return client;
};
export default createClient;
