import { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import DefaultLayout from "./components/Layout/DefaultLayout";
import { LoadingOutlined } from "@ant-design/icons";
import { Flex, Spin } from "antd";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { useAuth } from "./context/userContext";
import PrivateRoute from "./routes/privateRoute";
import { useAuthModule } from "@data-phone/react-resource-server-auth";
import { AppDispatch } from "./store/store";
import { useDispatch } from "react-redux";
import { setUser } from "./store/slices/user.slice";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

function App() {
  const [openAuthModal, setOpenAuthModal] = useState(true);
  const dispatch:AppDispatch = useDispatch()
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate()
  const { login, data ,logout} = useAuthModule();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    console.log("data", data);
    if(data.sessionData){
      dispatch(setUser(data.sessionData))
    }
    if (data.isAuthenticated) {
      setIsAuthenticated(true);
      navigate("/")
    }
    else{
      setIsAuthenticated(false);
    }
  }, [data]);

  if (isLoading) {
    return (
      <Spin
        fullscreen
        indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
      />
    );
  }
  return (
    <Routes>
      <Route
        element={
          <DefaultLayout
            logout={logout}
            openAuthModal={openAuthModal}
            setOpenAuthModal={setOpenAuthModal}
          />
        }
      >
        <Route path="/" element={ data.isAuthenticated ? <PrivateRoute /> : <Navigate to="/login" />}>
          <Route path="/" element={<Home />} />
        </Route>{" "}
        <Route
          path="/login"
          element={
            !data.isAuthenticated ? (
              <Login context={{ openAuthModal, setOpenAuthModal, login }} />
            ) : (
              <Navigate to="/" />
            )
          }
        />
      </Route>
    </Routes>
  );
}

export default App;
