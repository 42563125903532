import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { ConfigProvider } from "antd";
import LoginHeader from "./LoginHeader.layout";
import backgroundImg from "../../assets/images/Bg.png";
import { useState } from "react";
import { useAuth } from "../../context/userContext";
import LayoutHeader from "./Header.layout";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const DefaultLayout = ({
  openAuthModal,
  setOpenAuthModal,
  logout
}: {
  openAuthModal: boolean;
  setOpenAuthModal: React.Dispatch<React.SetStateAction<boolean>>;
  logout:()=>void
}) => {
  const logedInLayout: React.CSSProperties = {
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "0% 0%",
    backgroundRepeat: "no-repeat",
    height: "100vh",
  };
  const LayoutStyle: React.CSSProperties = {
    height: "100vh",
    background: "white",
  };
  const { isAuthenticated } = useAuth();
  const { data } = useSelector((state: RootState) => state.user);

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Rethink Sans",
        },
      }}
    >
      <Layout style={isAuthenticated ? LayoutStyle : logedInLayout}>
        {isAuthenticated ? (
          <LayoutHeader logout={logout} name={data?.firstName + " " + data?.lastName} />
        ) : (
          <LoginHeader
            openAuthModal={openAuthModal}
            setOpenAuthModal={setOpenAuthModal}
          />
        )}

        <Outlet />
      </Layout>
    </ConfigProvider>
  );
};

export default DefaultLayout;
