import { Flex, Typography } from "antd";
import Callcenterimg from "../assets/images/Call-Center.svg";
import contactsImg from "../assets/images/Contacts.svg";
import liveCallImg from "../assets/images/Live-calls.svg";
import PBXImg from "../assets/images/PBX-Portal.svg";
import ReportsImg from "../assets/images/Reports.svg";
import SMSImg from "../assets/images/SMS.svg";
const { Text } = Typography;


const TextStyle: React.CSSProperties = {
  color: "rgb(119, 117, 135)",
};

const HeadingStyle: React.CSSProperties = {
  fontSize: "30px",
  fontWeight: "700",
  lineHeight: "32px",
  marginBottom: "20px",
};

const containerStyle: React.CSSProperties = {
  height: "100%",
  justifyContent: "center",
  alignItems: "center",
};

const iconContainer: React.CSSProperties = {
  border: "2px solid #DFE2E8",
  borderRadius: "10px",
  padding: "20px",
};
const Home = () => {
  return (
    <>
      <Flex vertical={true} style={containerStyle}>
        <Text style={HeadingStyle}>Switch apps to avail service</Text>
        <Flex style={iconContainer} vertical={true}>
          <Flex>
            <Flex
              vertical={true}
              align="center"
              onClick={() => {
                window.location.href = process.env
                  .REACT_APP_LIVE_CALL_URL as string;
              }}
              className="home-menu-icons"
            >
              <img src={Callcenterimg} alt="Linkedin" />
              <Text style={TextStyle}>Live Calls</Text>
            </Flex>
            <Flex
              vertical={true}
              align="center"
              onClick={() => {
                window.location.href = process.env
                  .REACT_APP_LIVE_CALL_URL as string;
              }}
              className="home-menu-icons"
            >
              <img src={contactsImg} alt="apple icon" />
              <Text style={TextStyle}>Call center</Text>
            </Flex>
            <Flex
              vertical={true}
              align="center"
              className="home-menu-icons"
              onClick={() => {
                window.location.href = process.env.REACT_APP_SMS_URL as string;
              }}
            >
              <img src={liveCallImg} alt="behance icon" />
              <Text style={TextStyle}>SMS</Text>
            </Flex>
          </Flex>
          <Flex>
            <Flex
              vertical={true}
              align="center"
              onClick={() => {
                window.location.href = process.env
                  .REACT_APP_CONTACTS_URL as string;
              }}
              className="home-menu-icons"
            >
              <img src={PBXImg} alt="slack icon" />
              <Text style={TextStyle}>Contacts</Text>
            </Flex>{" "}
            <Flex vertical={true} align="center" className="home-menu-icons">
              <img src={ReportsImg} alt="slack new icon" />
              <Text style={TextStyle}>PBX Portal</Text>
            </Flex>{" "}
            <Flex vertical={true} align="center" className="home-menu-icons">
              <img src={SMSImg} alt="vk icon" />
              <Text style={TextStyle}>Reports</Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};

export default Home;
